/**
 * Copyright © 2013-2017 Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

define('WeltPixel_QuickCart/js/checkout/view/minicart',[
    'uiComponent',
    'jquery',
    'mage/translate'
], function (Component, $,) {
    'use strict';

    return function (Component) {
        return Component.extend({

            /**
             * @override
             */
            initialize: function () {
                $("[data-block='minicart']").on("dropdowndialogopen", function(e) {
                    $('body').addClass('minicart-active');
                });

                $("[data-block='minicart']").on("dropdowndialogclose", function(e) {
                    $('body').removeClass('minicart-active');
                });

                return this._super();
            },

            /**
             * @param {Object} item
             * @returns {String}
             */
            getDeliveryMessage: function (item) {
                var deliveryMessage = '';
                var currentTime = new Date();
                var timeInSevenDays = (new Date()).setDate(currentTime.getDate() + 6);
                var parsedPreorderDeadline = Date.parse(item.preorderdeadline);
                var parsedRestockDate = Date.parse(item.restock);
                var isPreorder = (item.preorder && !isNaN(parsedPreorderDeadline) && (currentTime.valueOf() < parsedPreorderDeadline));
                var isRestock = (item.restock && !isNaN(parsedRestockDate) && (parsedRestockDate >= timeInSevenDays.valueOf()));
                var dateOptions = {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                };
                var dateLocale = item.ma_locale.replace('_', '-');

                if (isPreorder) {
                    var preorderDate = new Date(parsedPreorderDeadline);
                    deliveryMessage = $.mage.__("Pre-order article available from %1").replace('%1', preorderDate.toLocaleDateString(dateLocale, dateOptions));
                }
                else if (isRestock) {
                    var restockDate = new Date(parsedRestockDate);
                    deliveryMessage = $.mage.__("Available again from %1").replace('%1', restockDate.toLocaleDateString(dateLocale, dateOptions));
                }
                else {
                    deliveryMessage = $.mage.__("Delivery time (working days) %1").replace('%1', item.delivery_time);
                }


                return deliveryMessage;
            },

            /**
             * @param {Object} item
             * @returns {String}
             */
            getDeliveryType: function (item) {
                var deliveryType = 'delivery';
                var currentTime = new Date();
                var timeInSevenDays = (new Date()).setDate(currentTime.getDate() + 6);
                var parsedPreorderDeadline = Date.parse(item.preorderdeadline);
                var parsedRestockDate = Date.parse(item.restock);
                var isPreorder = (item.preorder && !isNaN(parsedPreorderDeadline) && (currentTime.valueOf() < parsedPreorderDeadline));
                var isRestock = (item.restock && !isNaN(parsedRestockDate) && ((currentTime.valueOf() < parsedRestockDate) && (parsedRestockDate >= timeInSevenDays.valueOf())));

                if (isPreorder) {
                    deliveryType = 'preorder';
                }
                else if (isRestock) {
                    deliveryType = 'restock';
                }

                return deliveryType;
            }
        });
    }
});

